.graph-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputWrapper {
    display: flex;
    align-items: stretch;
}

svg {
    stroke-width: 2;
    font-size: x-large;
}