:root {
    background-color: #ffe4c4;
}

.flex-center-flex1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.main-header {
    height: 20vh;   
}

.main-body {
    height: 60vh;
}

.main-foot {
    height: 10vh;
}